@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans Bold';
  src: url('./fonts/NunitoSans-Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans Italic';
  src: url('./fonts/NunitoSans-Italic.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
