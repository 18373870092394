/*overide toast font size*/
.toast {
  font-size: 1.4rem;
}

/* overide styled for top-center info toast */
.toast-custom {
  width: 36rem !important;
  padding: 1.6rem !important;
  background-color: #363740;
  border-radius: 0.6rem !important;
  margin-top: 11rem !important;
  line-height: 1.9rem;
  letter-spacing: 0.03rem;
  box-shadow: none !important;
  opacity: 1 !important;
  background-image: none !important;
}

.toast-auto-width {
  max-width: fit-content;
}
