.flatpickr-months {
  margin-bottom: 3rem;
}

.flatpickr-weekdays {
  .flatpickr-weekday {
    color: #363740;
  }
}

.flatpickr-days {
  .flatpickr-day {
    &.today {
      border-color: #fc7d58;
      color: #fc7d58;
    }

    &.selected {
      border-color: #fc7d58;
      background-color: #fc7d58;
      color: white;
    }

    &.flatpickr-disabled {
      color: #a4a6b3;
    }
  }
}
