@import 'styles/fonts.scss';
@import 'styles/flatpickr-override.scss';
@import 'styles/toast-override.scss';

html,
body {
  height: 100%;
  font-size: 10px;
}

body {
  font-family: 'Nunito Sans', 'Segoe UI', Ubuntu, Cantarell, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;

  @media screen and (max-width: 767px) {
    overflow-x: hidden;
  }
}

/*Hide Arrows From Input Number*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*Hide Arrows From Input Number*/

/* Override Webkit autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

strong {
  font-family: 'Nunito Sans Bold';
}

// Google Place Autocomplete not showing in Bootstrap modal
.pac-container {
  z-index: 10000 !important;
}

//submit buttom to report a problem to Jira by CSR
//initially hidden for all users
#atlwdg-trigger {
  display: none;
}
